<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <Logo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="6"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />
          </div>
        </v-col>

        <v-col lg="6" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary my-2">
                    Crear nueva cuenta Proveedor 👨🏼‍🔧
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-alert
                    color="error"
                    outlined
                    type="error"
                    v-if="errorsValidation"
                  >
                    <div v-for="(errorArray, idx) in errorsValidation" :key="idx">
                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                            <span class="text-danger">{{ allErrors}} </span>
                        </div>
                    </div>
                  </v-alert>
                </v-card-text>

                <v-stepper v-model="activeStep" vertical class="custom-header">
                  <!-- Step 1 -->
                  <v-stepper-step :complete="activeStep > 1" step="1">
                    <div class="d-flex align-center">
                      <span class="text--primary text-4xl font-weight-bold me-3"
                        >01</span
                      >
                      <div class="d-flex flex-column">
                        <span class="text--primary text-sm font-weight-semibold"
                          >Información personal</span
                        >
                        <!-- <span class="text--secondary text-xs">Setup your account details</span> -->
                      </div>
                    </div>
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-form ref="step1Form" class="pb-6 pt-2">
                      <v-row>
                        <v-col>
                          <FormTextField
                            :value="formData.email"
                            label="Email"
                            placeholder="Email"
                            :rules="[
                              validators.required,
                              validators.emailValidator,
                            ]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'email')
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <FormTextField
                            :value="formData.rut"
                            label="RUT"
                            placeholder=""
                            :rules="[validators.required]"
                            class="mb-2"
                            @onChangeInput="(v) => onChangeFormInputs(v, 'rut')"
                          />
                        </v-col>
                        <v-col>
                          <FormTextField
                            :value="formData.phone"
                            label="Teléfono"
                            placeholder=""
                            :rules="[validators.required]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'phone')
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <FormTextFieldPassword
                            :value="formData.password"
                            label="Contraseña"
                            placeholder="minimo 8 carácteres"
                            :rules="[validators.required]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'password')
                            "
                          />
                        </v-col>
                        <v-col>
                          <FormTextFieldPassword
                            :value="formData.confirm_password"
                            label="Repetir contraseña"
                            placeholder="minimo 8 carácteres"
                            :rules="[
                              validators.required,
                              validators.confirmedValidator(
                                formData.password,
                                formData.confirm_password
                              ),
                            ]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'confirm_password')
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-stepper-content>

                  <!-- Step 2 -->
                  <v-stepper-step :complete="activeStep > 2" step="2">
                    <div class="d-flex align-center">
                      <span class="text--primary text-4xl font-weight-bold me-3"
                        >02</span
                      >
                      <div class="d-flex flex-column">
                        <span class="text--primary text-sm font-weight-semibold"
                          >Información empresa</span
                        >
                        <!-- <span class="text--secondary text-xs">Setup your account details</span> -->
                      </div>
                    </div>
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <v-form ref="step2Form" class="pb-6 pt-2">
                      <v-row>
                        <v-col>
                          <FormTextField
                            :value="formData.company"
                            label="Nombre de la empresa o persona"
                            placeholder=""
                            :rules="[validators.required]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'company')
                            "
                          />
                        </v-col>
                        <v-col>
                          <FormTextField
                            :value="formData.years_bussines"
                            label="Años de la empresa"
                            placeholder=""
                            :rules="[
                              validators.required,
                              validators.integerValidator,
                            ]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'years_bussines')
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <FormTextField
                            :value="formData.name"
                            label="Persona de contacto"
                            placeholder=""
                            :rules="[validators.required]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'name')
                            "
                          />
                        </v-col>
                        <v-col>
                          <FormTextField
                            :value="formData.address"
                            label="Dirección"
                            placeholder=""
                            :rules="[validators.required]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'address')
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <SelectLocationCities
                            v-model="formData.city_id"
                            :rules="[]"
                          />
                        </v-col>
                        <v-col>
                          <SelectLocationComunes
                            v-model="formData.location_id"
                            :rules="[]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <FormTextField
                            :value="formData.wattsapp"
                            label="Whatsapp"
                            placeholder=""
                            :rules="[]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'wattsapp')
                            "
                          />
                        </v-col>
                        <v-col>
                          <FormTextField
                            :value="formData.instagram"
                            label="Instagram"
                            placeholder=""
                            :rules="[]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'instagram')
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <FormTextField
                            :value="formData.facebook"
                            label="Facebook"
                            placeholder=""
                            :rules="[]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'facebook')
                            "
                          />
                        </v-col>
                        <v-col>
                          <FormTextField
                            :value="formData.linkedin"
                            label="Linkedin"
                            placeholder=""
                            :rules="[]"
                            class="mb-2"
                            @onChangeInput="
                              (v) => onChangeFormInputs(v, 'linkedin')
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <FormTextField
                            :value="formData.web"
                            label="Sitio web"
                            placeholder=""
                            :rules="[]"
                            class="mb-2"
                            @onChangeInput="(v) => onChangeFormInputs(v, 'web')"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-stepper-content>

                  <v-row>
                    <v-col>
                      <div class="d-flex justify-space-between mt-10 mx-8">
                        <div>
                          <v-btn
                            :disabled="loading"
                            color="default"
                            class="me-2"
                            v-if="activeStep > 1"
                            @click="activeStep = 1"
                          >
                            Volver
                          </v-btn>
                        </div>
                        <div>
                          <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="primary"
                            class="me-2"
                            @click="handleFormSubmit"
                          >
                            Siguiente
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-stepper>

                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">¿Ya tienes una cuenta?</p>
                  <router-link :to="{ name: 'auth-login' }">
                    Iniciar sesión
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/general/Logo";
import FormTextField from "@/components/forms/TextField";
import FormTextFieldPassword from "@/components/forms/TextFieldPassword";
import SelectLocationCities from "@/components/general/SelectLocationCities";
import SelectLocationComunes from "@/components/general/SelectLocationComunes";
import {
  required,
  emailValidator,
  integerValidator,
  confirmedValidator,
} from "@core/utils/validation";
import { ref, reactive, getCurrentInstance } from "@vue/composition-api";
import { useRouter } from "@core/utils";
import api from "@/services";

export default {
  components: {
    Logo,
    FormTextField,
    FormTextFieldPassword,
    SelectLocationCities,
    SelectLocationComunes,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { router, routerWelcome } = useRouter();

    const activeStep = ref(1);
    const loading = ref(false);
    const errorsValidation = ref(null);
    const formData = reactive({
      name: null,
      email: null,
      password: null,
      confirm_password: null,
      rut: null,
      phone: null,
      address: null,
      years_bussines: null,
      company: null,
      location_id: 1,
      city_id: 1,
      company_type_id: 1, // default
      role_id: 3, // default
      web: null,
      facebook: null,
      instagram: null,
      linkedin: null,
      wattsapp: null,
    });

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const onChangeFormInputs = (value, property) => {
      formData[property] = value;
    };

    const handleFormSubmit = async () => {
      switch (activeStep.value) {
        case 1:
          const step1FormValid = vm.$refs.step1Form.validate();
          if (step1FormValid) activeStep.value = 2;
          break;
        case 2:
          const step2FormValid = vm.$refs.step2Form.validate();
          if (step2FormValid) {
            onToggleLoading();

            try {
              errorsValidation.value = null;

              const { data: response } = await api.register(
                formData
              );
              const { token: accessToken, data, role } = response;

              if (accessToken) {
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem(
                  "userData",
                  JSON.stringify({ ...data, ...{ role } })
                );
                routerWelcome({ name: "worker.account", hash: "#add-categories" });
              }
            } catch ({response}) {
              const { errors = {} } = response.data;
              
              errorsValidation.value = errors;
              vm.$alert(`Imposible completar el registro, verifica los errores`, null, "error");
            }

            onToggleLoading();
          }
          break;
        default:
          return;
      }
    };

    return {
      formData,
      activeStep,
      loading,
      errorsValidation,
      validators: {
        required,
        emailValidator,
        integerValidator,
        confirmedValidator,
      },
      handleFormSubmit,
      onChangeFormInputs,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/preset/preset/pages/auth.scss";

.v-card {
  max-width: 100% !important;
}
</style>
